import React, { Component } from 'react'

export class Track extends Component {
  render() {
    return (
      <div>Track</div>
    )
  }
}

export default Track;